var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "icon-selector" },
    [
      _c(
        "b-dropdown",
        {
          ref: "iconDropdown",
          staticClass: "flex-grow-1",
          attrs: { placeholder: "Icon", split: "", disabled: _vm.disabled },
          scopedSlots: _vm._u([
            {
              key: "button-content",
              fn: function() {
                return [
                  _vm.selectedIcon
                    ? _c("span", { staticClass: "icon-preview" }, [
                        _c("i", {
                          staticClass: "mr-3 fa",
                          class: _vm.selectedIcon,
                          style: "color: " + _vm.iconColor + ";"
                        }),
                        _c("div", { staticClass: "flex-grow-1" }, [
                          _vm._v(_vm._s(_vm.selectedIcon))
                        ])
                      ])
                    : _c("span", [
                        _vm._v(" " + _vm._s(_vm.placeholderText) + " ")
                      ])
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.selectedIcon,
            callback: function($$v) {
              _vm.selectedIcon = $$v
            },
            expression: "selectedIcon"
          }
        },
        [
          _c("b-dropdown-form", { staticClass: "clickable flex-row" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.iconFilter,
                  expression: "iconFilter"
                }
              ],
              attrs: { placeholder: "Filter Icons..." },
              domProps: { value: _vm.iconFilter },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.iconFilter = $event.target.value
                }
              }
            })
          ]),
          _vm._l(_vm.filteredIconOptions, function(icon, idx) {
            return _c(
              "b-dropdown-form",
              {
                key: "icon-" + idx,
                staticClass: "clickable flex-row",
                on: {
                  click: function($event) {
                    return _vm.selectIcon(icon)
                  }
                }
              },
              [
                _c("i", { staticClass: "mr-2 fa", class: icon }),
                _vm._v(_vm._s(icon) + " ")
              ]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }