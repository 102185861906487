<template>
  <div class="icon-selector">
      <b-dropdown class="flex-grow-1" placeholder="Icon" v-model="selectedIcon" split ref="iconDropdown"
                  :disabled="disabled">
        <template #button-content>
                      <span v-if="selectedIcon" class="icon-preview">
                          <i class="mr-3 fa" :class="selectedIcon" :style="'color: ' + iconColor + ';'"></i>
                        <div class="flex-grow-1">{{selectedIcon}}</div>

                      </span>
          <span v-else>
                          {{placeholderText}}
                      </span>
        </template>
        <b-dropdown-form class="clickable flex-row">
          <input v-model="iconFilter" placeholder="Filter Icons...">
        </b-dropdown-form>
        <b-dropdown-form v-for="(icon, idx) of filteredIconOptions" v-bind:key="'icon-' + idx"
                         class="clickable flex-row" @click="selectIcon(icon)">
          <i class="mr-2 fa" :class="icon"></i>{{icon}}
        </b-dropdown-form>
      </b-dropdown>
  </div>
</template>
<script>

import {faIcons} from '@/components/helpers/IconHelper'

export default {
  name: 'IconSelector',
  props: {
    placeholder: String,
    value: String,
    color: String,
    disabled: Boolean
  },
  emits: [
    'input'
  ],
  data () {
    return {
      iconFilter: '',
      iconOptions: faIcons,
      selectedIcon: null
    }
  },
  async mounted () {
    if (this.value) {
      this.selectedIcon = this.value
    }
  },
  methods: {
    selectIcon(icon) {
      this.selectedIcon = icon
      this.$refs.iconDropdown.hide(true)
      this.$emit('input', icon)
    }
  },
  computed: {
    placeholderText () {
      return this.placeholder || 'Select Icon'
    },
    iconColor () {
      return this.color || '#FFFFFF'
    },
    filteredIconOptions () {
      if (this.iconFilter !== '') {
        return faIcons.filter(x => x.includes(this.iconFilter))
      } else {
        return faIcons
      }
    }
  },
  watch: {
    value (newVal) {
      this.selectedIcon = newVal
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../variables';
.icon-selector {
  display: flex;
}

.icon-preview {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  .fa {
    font-size: 1em;
  }
}

</style>
